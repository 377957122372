// JS for .su-more-dates component
const button = document.querySelector('.su-more-dates__button');
const buttonText = document.querySelector('.su-more-dates__button-text');
const dateList = document.getElementById('x-all-dates');
var buttonAttr = button ? button.attributes : null;
var listAttr = dateList ? dateList.attributes : null;

function openDates() {
  if (buttonAttr['aria-expanded'].value == 'false' && buttonText.textContent == 'Show') {
    button.setAttribute('aria-expanded', 'true');
    buttonText.textContent = 'Hide';
  } else {
    button.setAttribute('aria-expanded', 'false');
    buttonText.innerText = 'Show';
  };
  if (dateList.classList.contains('su-hide') && listAttr['aria-hidden'].value == 'true') {
    dateList.classList.remove('su-hide');
    dateList.classList.add('su-show');
    dateList.setAttribute('aria-hidden', 'false')
  } else {
    dateList.classList.remove('su-show');
    dateList.classList.add('su-hide');
    dateList.setAttribute('aria-hidden', 'true')
  };
};

button ? button.addEventListener('click', openDates, false) : null;

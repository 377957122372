// Align sidebar for event detail pages

const pageBanner = document.getElementsByClassName("su-detail-banner")[0];
const card = document.getElementsByClassName("su-event-detail__sidebar")[0];
const brandBarBox = document.getElementsByClassName("su-brand-bar__container")[0];

function isXs() {
	const screenMargin = getComputedStyle(brandBarBox, null).marginLeft;
	return ((screenMargin === "20px") || (screenMargin === "30px"));
}

function setNegativeMargin(elem) {
	if (!isXs()) {
		const titleHeight = pageBanner ? pageBanner.querySelector("#event-title").clientHeight : null;
		const bannerPaddingBottom = pageBanner ? getComputedStyle(pageBanner, null).paddingBottom.slice(0, -2) : null;
		const fineTuning = 7;
		const cardMargin = -titleHeight - bannerPaddingBottom + fineTuning;
		elem ? elem.style.marginTop = cardMargin + 'px' : null;
	} else {
		elem.style.marginTop = 0;
	}
}

function debounce(func, wait, immediate) {
	let timeout;
	return function () {
		var context = this, args = arguments;
		var later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}

const setNegativeMarginOnResize = debounce(function () {
	setNegativeMargin(card);
}, 250);

document.addEventListener('DOMContentLoaded', function () {
	setNegativeMargin(card);
});

window.addEventListener('resize', setNegativeMarginOnResize);
